import React from 'react';

import quote from './assets/quote.svg';
import firstAvatar from './assets/dharmesh.jpg';
import secondAvatar from './assets/jordan.jpg';

import { Image, Heading, Button, Waves } from 'components';

import styles from './styles.module.scss';

const Quote = ({ name, occupation, children, avatar }) => (
  <div className={styles.quote}>
    <div className={styles.left}>
      <Image width="65" height="65" src={avatar} />
    </div>
    <div className={styles.right}>
      <div className={styles.name}>{name}</div>
      <div className={styles.occupation}>{occupation}</div>
      <div className={styles.quoteLine}>
        <img src={quote} alt="" />
        <span className={styles.line} />
      </div>
      <div className={styles.quote}>{children}</div>
    </div>
  </div>
);

const Quotes = ({ openBecomeASpeakerModal }) => (
  <div className={styles.wrapper}>
    <Waves />
    <div className={styles.container}>
      <div className={styles.left}>
        <Heading color="white" className={styles.title} size="medium">
          We’ve had some of the world’s leading PropTech influencers speak at
          Connect.
        </Heading>

        <Button
          textColor="white"
          backgroundColor="blue"
          borderColor="blue"
          size="medium"
          onClick={openBecomeASpeakerModal}>
          Become a Speaker
        </Button>
      </div>

      <div className={styles.right}>
        <Quote
          name="Dharmesh Mistry"
          occupation="Prop-Fin-Tech Innovator, FinTech Futures writer"
          avatar={firstAvatar}>
          What Unissu achieved logistically and end output is truly phenomenal
          and ground breaking, and I say this having been to some of the biggest
          ‘traditional’ conferences out there. Congratulations!
        </Quote>
        <Quote
          name="Jordan Kostelac"
          occupation="Head of PropTech, JLL Asia"
          avatar={secondAvatar}>
          Innovation is all about applying unconventional means to solve
          challenges. We are pleased to support Connect.
        </Quote>
      </div>
    </div>
  </div>
);

export default Quotes;
